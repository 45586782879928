.Box {
    margin: 10px;
    padding: 20px;
    background-color: #F2DEDE;
    border: 1px solid #ccc;
    border: 2px solid transparent;
    gap: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Box.connected {
    background-color: #DFF0D8;
}

.title {
    font-size: 1.2em;
    border: 2px solid transparent;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.connected {
    border-color: #4CAF50;
}

.disconnected {
    border-color: #F44336;
}

.connected .title {
    color: #4CAF50;
}

.disconnected .title {
    color: #F44336;
}
.reads{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}