.tag-item {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 310px;
}

.tag-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
}

.tag-info div {
    margin: 0;
}

.time {
  font-size: 0.8em;
}

.rssi-and-antenna-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7em;
}

.rssi-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.antenna {
    display: flex;
    align-items: center;
    gap: 5px;
}

.rssi-bar {
    width: 100px;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.rssi-bar::after {
    content: "";
    display: block;
    height: 100%;
    width: calc(var(--rssi-value) * 1%);
    background-color: green;
    border-radius: 5px 0 0 5px;
}

.rssi-value {
    font-weight: bold;
}

.rssi-label {
    font-weight: normal;
}